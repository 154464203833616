import React, { useEffect, useState, useRef } from 'react';
import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import * as z from "zod"
import { useNavigate } from 'react-router-dom';
import { Button } from "./ui/button"
import { useToast } from "../components/ui/use-toast"
import Image  from "../components/image"
import ImageGrid from '../components/imageGrid'
import posthog from 'posthog-js'
import Loading from "../components/GIF"

import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { auth } from './firebase'; // Make sure to import your Firebase configuration
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "./ui/form"
import { Input } from "./ui/input"

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

export const adVariations = {
  'original': {
    header: "Find the proper policy for you (100% Free)",
    subhead: "Get personalized suggestions from 5 insurance experts, no phone number needed. Plus a chance to get your premiums paid by us! <b>Just takes 5 minutes to start!</b>"
  },
  'high-intent': {
    header: "Why get 1 perspective when you can get 5",
    subhead: "Get <b>5 different insurance experts</b> opinions in less time than it takes to hear from 1. Completely online allowing you to <b>stay anonmyous</b> takes only 5 minutes to get started.",
    cta: "Get started →"
  },
  'variation': {
    header: "Get 5 Expert Quotes and Advice in Just 5 Minutes",
    subhead: "Compare personalized insurance quotes and receive expert advice from 5 different advisors. No phone calls, no commitment - just <b>clear, actionable information</b> to help you make the best choice.",
    cta: "Get started →"
  },
  'term': {
    header: "Find The Proper Term Life Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'whole': {
    header: "Find The Proper Whole Life Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'ci': {
    header: "Find The Proper Critical Illness Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'endowment': {
    header: "Find The Proper Endowment Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'hospital': {
    header: "Find The Proper Hospital (ISP) Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'ilp': {
    header: "Find The Proper Investment Linked Plan For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'pa': {
    header: "Find The Proper Personal Accident Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  },
  'tpd': {
    header: "Find The Proper Total Permanent Disability Insurance For You With Ease",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies without sharing your phone number <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  }
}

export function useAdVariation() {
  const [adVariation, setAdVariation] = useState<string>('');

  useEffect(() => {
    let variation = localStorage.getItem('adVariation');
    if (!variation) {
      const variations = ['high-intent', 'original'];
      variation = variations[Math.floor(Math.random() * variations.length)];
      localStorage.setItem('adVariation', variation);
    }
    setAdVariation(variation);
  }, []);

  return adVariation;
}

export const headerVariations = {
  1: {
    header: "Personalized Insurance Guidance. Premium-Free Coverage.",
    subhead: "Get matched with 5 expert advisors and a chance to win your monthly premiums paid.",
  },
  2: {
    header: "Unlock Personalized Insurance Advice. Win Premiums On Us.",
    subhead: "Discover the right coverage with 5 expert recommendations - and pay $0 in premiums.",
  },
  3: {
    header: "Effortless Insurance Advice. Premium-Free Protection.",
    subhead: "Connect with 5 advisors, find your perfect policy, and win your monthly premiums.",
  },
  4: {
    header: "Tailored Insurance Solutions. Premium-Free for a Month.",
    subhead: "Get 5 personalized recommendations and a shot at having your premiums covered.",
  },
  5: {
    header: "Expert Insurance Guidance. Premium-Free for 30 Days.",
    subhead: "Explore 5 advisor matches and enter to win a month of free insurance coverage.",
  },
  6: {
    header: "Personalized Insurance Advice. Premiums On Us.",
    subhead: "Discover your ideal policy with 5 expert recommendations and a chance to win.",
  },
  7: {
    header: "Effortless Insurance Matching. Premium-Free Payout.",
    subhead: "Connect with 5 advisors, find your fit, and enter to have your premiums paid.",
  },
  8: {
    header: "Personalized Insurance Recommendations. Premium-Free Prize.",
    subhead: "Get 5 expert suggestions and a shot at having your monthly costs covered.",
  },
  9: {
    header: "Insurance Guidance Made Easy. Premiums On Us Giveaway.",
    subhead: "Explore 5 advisor matches and enter to win a month of premium-free coverage.",
  },
  10: {
    header: "Personalized Insurance Advice. Premium-Free for 30 Days.",
    subhead: "Get 5 expert recommendations and a chance to have your monthly costs covered.",
  },
  11: {
    header: "The Better Way of Finding The Proper Policy For You",
    subhead: "Get <b className='text-xl'>personalised suggestions</b> from <b className='text-xl'>5 insurance experts</b> from different companies <b className='text-xl'>100% free</b> and stand to win your <b className='text-xl'>Premiums On Us</b>."
  }
};

const formSchema = z.object({
  alias: z.string().min(2, {
    message: "Username must be at least 2 characters.",
  }),
  email: z.string().email({
    message: "Please enter a valid email address.",
  })
})



function createOnSubmit({
  apiEndpoint,
  successToast,
  errorToast,
  posthogEventName,
  posthogEventData,
  dataLayerEventName,
  dataLayerEventData,
  navigationPath,
  toast,
  navigate,
  setIsLoading
}) {
  return async function onSubmit(values: z.infer<typeof formSchema>, signUpSource: 'email' | 'google') {
    setIsLoading(true);
    const lowercaseEmail = values.email.toLowerCase();
    const currentUrl = window.location.href;
    
    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          ...values, 
          email: lowercaseEmail, 
          url: currentUrl,
          posthogId: posthog.get_distinct_id(),
          signUpSource: signUpSource
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        toast({
          title: 'Error',
          description: errorData.error || errorToast.description,
          variant: 'destructive',
          duration: 10000,
        });
      } else {
        toast(successToast);
        
        const responseData = await response.json();
        try {
          posthog.capture(posthogEventName, {
            ...posthogEventData(values, currentUrl, signUpSource),
          });
        } catch (error) {
          console.error('Error firing Posthog event:', error);
        }


        if (typeof window !== 'undefined' && window.dataLayer) {
          window.dataLayer.push({
            event: dataLayerEventName,
            ...dataLayerEventData(values, signUpSource),
          });
        }

        if (navigationPath) {
          navigate(navigationPath, { state: { data: responseData } });
        }
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    } finally {
      setIsLoading(false);
    }
  };
}

function GetStartedFormContent({ 
  sidebarLayout = true, 
  source = null, 
  randomVariation = '', 
  cta = 'Get your free personalised plans →',
  apiEndpoint = '/api/get-proposals',
  successToastMessage = "Now we just need a few details about you. Will take less than 5 minutes!",
  errorToastMessage = "We didn't get your request. Please submit again.",
  posthogEventName = 'prospect_signed_up',
  dataLayerEventName = 'sign_up',
  navigationPath = '/questionnaire/start',
  showGoogleSignIn = true,
  showNameField = true,
  additionalFields = []
}) {
  const navigate = useNavigate();
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState(false);

  const formSchema = z.object({
    ...(showNameField && { alias: z.string().min(2, { message: "Username must be at least 2 characters." }) }),
    email: z.string().email({ message: "Please enter a valid email address." }),
    ...additionalFields.reduce((acc, field) => ({ ...acc, [field.name]: field.validation }), {})
  });

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      ...(showNameField && { alias: "" }),
      email: "",
      ...additionalFields.reduce((acc, field) => ({ ...acc, [field.name]: field.defaultValue }), {})
    },
  });

  const onSubmit = createOnSubmit({
    apiEndpoint,
    successToast: {
      title: `✅ Signed up successfully`,
      description: successToastMessage
    },
    errorToast: {
      title: `Whoops...`,
      description: errorToastMessage
    },
    posthogEventName,
    posthogEventData: (values, currentUrl, signUpSource) => ({
      name: values.alias,
      form: 'cta',
      url: currentUrl,
      $set_once: { header_version: randomVariation },
      signUpSource: signUpSource
    }),
    dataLayerEventName,
    dataLayerEventData: (values, signUpSource) => ({
      formType: 'lp',
      userName: values.alias,
      userEmail: values.email.toLowerCase(),
      signUpSource: signUpSource
    }),
    navigationPath,
    toast,
    navigate,
    setIsLoading
  });

  const handleGoogleSignIn = async () => {
    try {
      const googleProvider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      
      const values = {
        alias: user.displayName || '',
        email: user.email || '',
      };
      
      await onSubmit(values, 'google');
    } catch (error) {
      console.error("Error signing in with Google:", error);
      toast({
        title: 'Sign-In Error',
        description: 'There was an error signing in with Google. Please try again.',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      {isLoading && <div className="w-full h-full flex items-center justify-center">
        <Loading src="loading.mp4" />;
      </div>}
      <Button onClick={handleGoogleSignIn} className="w-full border border-gray-600 text-gray-700 font-semibold py-2 px-4 rounded flex items-center justify-center bg-white-2 rounded-lg">
  <svg className="w-6 h-6 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z" />
    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z" />
    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z" />
    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z" />
    <path fill="none" d="M0 0h48v48H0z" />
  </svg>
  Continue with Google
</Button>
<p className="text-center my-2">
  or
</p>
<Form {...form}>
        <form onSubmit={form.handleSubmit((values) => onSubmit(values, 'email'))} className="space-y-4">
          <FormField
            control={form.control}
            name="alias"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="relative">
                    <Input 
                      placeholder="Enter your name" 
                      {...field} 
                      className="pl-10 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <svg className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                    </svg>
                  </div>
                </FormControl>
                <FormMessage className="text-red-500 text-sm mt-1" />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <div className="relative">
                    <Input 
                      placeholder="Enter your email" 
                      {...field} 
                      className="pl-10 bg-white border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                    />
                    <svg className="w-5 h-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>
                  </div>
                </FormControl>
                <FormMessage className="text-red-500 text-sm mt-1" />
              </FormItem>
            )}
          />
           <Button variant='primary_2' type="submit" className="w-full">
            {cta}
          </Button>
        </form>
      </Form>
      
      {source && (
      <div className="mt-4 text-center">
        <p className="text-gray-600 text-sm">100% online. No number required.</p>
        
          <a href={`/lp/get-proposals?source=${source}`} className="primary-link">
            Learn more
          </a>
        
      </div>
      )}
    </div>
  );
}


export default GetStartedFormContent;

export function GetStartedSample({source='cta'}) {
  return (
    <div className='flex flex-col'>
        <div className='flex flex-col md:flex-row gap-14 max-w-screen-xl mx-auto px-4 py-8'>
          <div className='object-scale-down h-1/2 flex basis-1/2 order-last md:order-first'>
            <ImageGrid urls={['sample-proposal/sample-proposal-1-4x5.jpg','sample-proposal/sample-proposal-2-4x5.jpg','sample-proposal/sample-proposal-3-4x5.jpg','sample-proposal/sample-proposal-4-4x5.jpg','sample-proposal/sample-proposal-5-4x5.jpg']}
            maxHeight="h-1/2"/>
          </div>
          <div className='order-2 flex flex-col justify-center basis-1/2 gap-8'>
          <GetStartedForm sidebarLayout source={source} />
          </div>
        </div>
      </div>
  )
}

export function GetStartedTestimonial({testimonial=1, source='cta'}) {
  return (
    <div className='flex flex-col md:mx-6'>
      <div className='flex flex-col md:flex-row gap-4 md:gap-14 max-w-screen-xl mx-auto px-4 py-8'>
      <div className='flex flex-col justify-center gap-8 basis-1/2'>
      <GetStartedForm sidebarLayout source={source}  />
          </div>
      <div className="py-4 md:py-8 md:px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 basis-1/2">
            <div className="gap-8">
              { testimonial === 1 ? (
                <figure className="flex flex-col p-6 bg-white-2 rounded">
                    <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white text-pretty">Phone number really isn't needed!</h3>
                        <p className="my-4">"I loved that I didn't need to give out my phone number to get great advice. They craeted a telegram bot that connects the advisors to me so the advisors don't get my personal ID”</p>
                    </blockquote>
                    <figcaption className="flex items-center space-x-3 mt-auto">
                      <Image src='generic/Female 10.svg' className='w-16 h-16 rounded-full'/>
                        <div className="space-y-0.5 font-medium dark:text-white justity-start">
                            <div>Lynn</div>
                            <div>26</div>
                        </div>
                    </figcaption>    
                </figure>
              ) : testimonial === 2 ? (
                <figure className="flex flex-col p-6 bg-white-2 rounded">
                  <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
                    <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Great experience</h3>
                    <p className="my-4">"I have some underlying conditions and I was amazed at how the advisors were able to give me very helpful suggestions."</p>
                  </blockquote>
                  <figcaption className="flex items-center space-x-3 mt-auto">
                    <Image src='generic/Male 12.svg' className='w-16 h-16 rounded-full'/>
                    <div className="space-y-0.5 font-medium dark:text-white">
                      <div>Joseph</div>
                      <div>35</div>
                    </div>
                  </figcaption>
                </figure>
              ) : testimonial === 3 ? (
                <figure className="flex flex-col p-6 bg-white-2 rounded">
                    <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
                        <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Would recommend</h3>
                        <p className="my-4">"I appreciate this service because I can instantly see when an advisor tries to sell me things I don't need as their premiums are much higher than the rest."</p>
                    </blockquote>
                    <figcaption className="flex items-center space-x-3 mt-auto">
                    <Image src='generic/Female 4.svg' className='w-16 h-16 rounded-full'/>
                        <div className="space-y-0.5 font-medium dark:text-white">
                            <div>Amanda</div>
                            <div>25</div>
                        </div>
                    </figcaption>    
                </figure>
              ) : <figure className="flex flex-col p-6 bg-gray-50 rounded dark:bg-gray-800">
              <blockquote className="flex-1 text-sm text-gray-500 dark:text-gray-400">
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">Great experience</h3>
                <p className="my-4">"I have some underlying conditions and I was amazed at how the advisors were able to give me very helpful suggestions."</p>
              </blockquote>
              <figcaption className="flex items-center space-x-3 mt-auto">
                <Image src='generic/Male 12.svg' className='w-16 h-16 rounded-full'/>
                <div className="space-y-0.5 font-medium dark:text-white">
                  <div>Joseph</div>
                  <div>35</div>
                </div>
              </figcaption>
            </figure>
              }
            </div>   
      </div>
      
      </div>
    </div>
  )
}

export function GetStartedForm({ sidebarLayout = false, sample = false, source=null }) {
  const adVariation = useAdVariation();
  const { header, subhead, cta } = adVariations[adVariation || 'high-intent'];


  return (
    <div className={`${sidebarLayout ? 'py-4' : 'py-8 md:py-24'} text-center max-w-screen-2xl mx-auto`}>
      <h3 className="text-h4 font-headline text-pretty text-green-1 mb-4 capitalize text-start md:text-center " dangerouslySetInnerHTML={{ __html: header }}></h3>
      <p className="mb-8 max-w-screen-md mx-auto text-start md:text-center" dangerouslySetInnerHTML={{ __html: subhead }}></p>
      <GetStartedFormContent sidebarLayout={sidebarLayout} source={source} randomVariation={adVariation} cta={cta}/>
    </div>
  );
}



export function GetStarted({source=null}) {
  return <GetStartedForm source={source} />;
}

export function GetStartedSidebar({source='cta'}) {
  return <GetStartedForm sidebarLayout source={source} />;
}
import React, { useState, useEffect } from 'react';
import { useToast } from '../components/ui/use-toast';
import { useNavigate } from 'react-router-dom';
import { Checkbox } from '../components/ui/checkbox';
import {
 Table,
 TableBody,
 TableCell,
 TableHead,
 TableHeader,
 TableRow,
} from '../components/ui/table';
import {
 Accordion,
 AccordionItem,
 AccordionTrigger,
 AccordionContent,
} from '../components/ui/accordion';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

const DeleteComponent = () => {
 const [id, setId] = useState('');
 const { toast } = useToast();

 const handleDelete = async () => {
  try {
   const response = await fetch(`/api/delete-fake`, {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({ id }),
   });
   if (response.status === 200) {
    toast({
     title: `${id} was successfully deleted`,
    });
   } else if (response.status === 400) {
    const error = await response.json(); // Assuming the error message is in the response body
    toast({
     title: `Error deleting`,
     description: error.message, // Assuming error message is provided by your API
    });
   }
  } catch (error) {
   toast({
    title: `Error deleting`,
    description: error.message,
   });
  }
 };

 // Modif

 return (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2">
   <h1 className="text-xl font-bold">Manually delete prospect</h1>
   <p>
    This is for all the fake prospects we create. It will delete the prospect
    from airtable, firebase and convertkit
   </p>
   <input
    type="text"
    className="px-2"
    value={id}
    onChange={e => setId(e.target.value)}
    placeholder="Enter ID to delete"
   />
   <button
    className="bg-purple-500 p-2 rounded-2xl text-white"
    onClick={handleDelete}
   >
    Delete
   </button>
  </div>
 );
};

const AddComponent = () => {
 const [prospects, setProspects] = useState([]);
 const [advisors, setAdvisors] = useState([]);
 const [selectedProspectID, setSelectedProspectID] = useState('');
 const [selectedAdvisors, setSelectedAdvisors] = useState([]);
 const { toast } = useToast();
 const [isLoading, setIsLoading] = useState(false);

 useEffect(() => {
  fetchProspectsAndAdvisors();
 }, []);

 const fetchProspectsAndAdvisors = async () => {
  try {
   const response = await fetch('/api/get-fully-qualified');
   const data = await response.json();
   setProspects(data.prospects);
   setAdvisors(data.advisors);
  } catch (error) {
   console.error('Error fetching data:', error);
  }
 };

 const handleAdvisorSelection = (advisorId, isChecked, isFree) => {
  if (isChecked) {
   setSelectedAdvisors([...selectedAdvisors, { id: advisorId, isFree }]);
  } else {
   setSelectedAdvisors(
    selectedAdvisors.filter(advisor => advisor.id !== advisorId)
   );
  }
 };

 const handleAssign = async () => {
  setIsLoading(true);
  try {
   const response = await fetch('/api/manual-add', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     prospectID: selectedProspectID,
     advisorIDs: selectedAdvisors,
    }),
   });
   if (response.ok) {
    toast({
     title: `Successfully assigned advisors to prospect ${selectedProspectID}`,
    });
    setSelectedProspectID('');
    setSelectedAdvisors([]);
   } else {
    const error = await response.json();
    toast({
     title: `Error assigning advisors`,
     description: error.message,
    });
   }
  } catch (error) {
   toast({
    title: `Error assigning advisors`,
    description: error.message,
   });
  }
  setIsLoading(false);
 };

 return (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4">
   <h1 className="text-xl font-bold">Assign Advisors to Prospect</h1>

   <Accordion type="single" collapsible className="w-full">
    <AccordionItem value="prospects">
     <AccordionTrigger>Prospects</AccordionTrigger>
     <AccordionContent>
      {prospects.map(prospect => (
       <div key={prospect.id} className="flex items-center space-x-2">
        <input
         type="radio"
         id={prospect.id}
         name="prospect"
         value={prospect.id}
         checked={selectedProspectID === prospect.id}
         onChange={() => setSelectedProspectID(prospect.id)}
        />
        <label htmlFor={prospect.id}>
         {prospect.fields.Name} ({prospect.id})
        </label>
       </div>
      ))}
     </AccordionContent>
    </AccordionItem>
   </Accordion>
   <div className="grid grid-cols-5">
    <div className="col-span-2 border-r border-gray-200 px-2">
     {selectedProspectID && (
      <div className="prospect-details">
       <h2 className="font-bold text-xl capitalize primary-h3 text-green-1">
        Prospect Profile
       </h2>
       {prospects.find(p => p.id === selectedProspectID)?.fields && (
        <ul className="space-y-4">
         <li>
          <div className="flex justify-between mb-4 text-xl font-bold dark:text-white">
           Name/Alias:{' '}
           <span className="text-right mb-2 text-lg font-light dark:text-white">
            {prospects.find(p => p.id === selectedProspectID).fields.Name}
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Gender:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {prospects.find(p => p.id === selectedProspectID).fields.Gender}
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Birthday:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {prospects.find(p => p.id === selectedProspectID).fields.Birthday}
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Singaporean?:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {prospects.find(p => p.id === selectedProspectID).fields[
             'Singaporean?'
            ]
             ? 'Yes'
             : 'No'}
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Occupation:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {prospects.find(p => p.id === selectedProspectID).fields.Occupation}
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Monthly Income:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Monthly Income'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Relationship Status:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Relationship Status'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Current Kids:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Current Kids'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           More Kids In Future:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'More Kids In Future'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Other Dependants:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Other Dependants'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Smoker?:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {prospects.find(p => p.id === selectedProspectID).fields['Smoker?']
             ? 'Yes'
             : 'No'}
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Medical Condition:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Medical Condition'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Existing Policies:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Existing Policies'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Types Name:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {prospects
             .find(p => p.id === selectedProspectID)
             .fields['Types Name']?.join(', ')}
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Budget:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {prospects.find(p => p.id === selectedProspectID).fields['Budget']}
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Ideal Property Budget:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Ideal Property Budget'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Other Aspirations:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Other Aspirations'
             ]
            }
           </span>
          </div>
         </li>
         <li>
          <div className="flex justify-between gap-y-2 mb-2 text-sm font-medium dark:text-white">
           Phone Number:{' '}
           <span className="text-right mb-2 text-sm font-light dark:text-white">
            {
             prospects.find(p => p.id === selectedProspectID).fields[
              'Phone Number'
             ]
            }
           </span>
          </div>
         </li>
        </ul>
       )}
      </div>
     )}
    </div>
    <div className="col-span-3">
     <Table>
      <TableHeader>
       <TableRow>
        <TableHead>Name</TableHead>
        <TableHead>Company Name</TableHead>
        <TableHead>Team</TableHead>
        <TableHead>Last Prospect</TableHead>
        <TableHead>Total Leads Owed</TableHead>
        <TableHead>Assign</TableHead>
        <TableHead>Free</TableHead>
       </TableRow>
      </TableHeader>
      <TableBody>
       {advisors.map(advisor => (
        <TableRow key={advisor.id}>
         <TableCell>{advisor.fields.Name}</TableCell>
         <TableCell>{advisor.fields['Company Name']}</TableCell>
         <TableCell>{advisor.fields.Team}</TableCell>
         <TableCell>
          {advisor.fields['Last Prospect']
           ? new Date(advisor.fields['Last Prospect']).toLocaleDateString(
              'en-GB'
             )
           : 'N/A'}
         </TableCell>
         <TableCell>{advisor.fields['Total Leads Owed']}</TableCell>
         <TableCell>
          <Checkbox
           checked={selectedAdvisors.some(a => a.id === advisor.id)}
           onCheckedChange={checked =>
            handleAdvisorSelection(advisor.id, checked, false)
           }
          />
         </TableCell>
         <TableCell>
          <Checkbox
           checked={selectedAdvisors.some(a => a.id === advisor.id && a.isFree)}
           onCheckedChange={checked =>
            handleAdvisorSelection(advisor.id, checked, true)
           }
          />
         </TableCell>
        </TableRow>
       ))}
      </TableBody>
     </Table>

     <button
      onClick={handleAssign}
      disabled={
       !selectedProspectID || selectedAdvisors.length === 0 || isLoading
      }
      className="primary-button2 w-full"
     >
      {isLoading ? 'Assigning...' : 'Assign'}
     </button>
    </div>
   </div>
  </div>
 );
};

const AssignAdvisorsComponent = () => {
 const [prospectID, setProspectID] = useState('');
 const [slots, setSlots] = useState('');
 const [releaseDate, setReleaseDate] = useState('');
 const [isLoading, setIsLoading] = useState(false);
 const { toast } = useToast();

 const formatDate = dateString => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
 };

 const handleAssign = async () => {
  setIsLoading(true);
  try {
   const formattedReleaseDate = formatDate(releaseDate);
   const response = await fetch(`../api/assign-advisors`, {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({
     prospectID,
     member_slots: slots ? parseInt(slots) : undefined,
     releaseDate: formattedReleaseDate,
    }),
   });

   if (response.ok) {
    const data = await response.json();
    toast({
     title: `Successfully assigned advisors to prospect ${prospectID}`,
     description: `${data.exclusiveWinners.length} advisors assigned. Release date: ${formattedReleaseDate}`,
    });
   } else {
    const error = await response.json();
    toast({
     title: `Error assigning advisors`,
     description: error.message,
     variant: 'destructive',
    });
   }
  } catch (error) {
   toast({
    title: `Error assigning advisors`,
    description: error.message,
    variant: 'destructive',
   });
  }
  setIsLoading(false);
 };

 return isLoading ? (
  <div>Loading...</div>
 ) : (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2">
   <h1 className="text-xl font-bold">Assign Advisors to Prospect</h1>
   <p>This will assign advisors to the specified prospect</p>
   <input
    type="text"
    className="px-2 py-1 rounded"
    value={prospectID}
    onChange={e => setProspectID(e.target.value)}
    placeholder="Prospect ID"
   />
   <input
    type="number"
    className="px-2 py-1 rounded"
    value={slots}
    onChange={e => setSlots(e.target.value)}
    placeholder="Number of slots (optional)"
   />
   <input
    type="date"
    className="px-2 py-1 rounded"
    value={releaseDate}
    onChange={e => setReleaseDate(e.target.value)}
    placeholder="Release Date"
   />
   <button
    className="bg-purple-500 p-2 rounded-2xl text-white hover:bg-purple-600 transition-colors"
    onClick={handleAssign}
   >
    Assign Advisors
   </button>
  </div>
 );
};

const ViewAsAdvisorComponent = () => {
 const [advisorID, setAdvisorID] = useState('');
 const { toast } = useToast();
 const [isLoading, setIsLoading] = useState(false);

 const handleViewAsAdvisor = async () => {
  setIsLoading(true);
  try {
   const response = await fetch('/api/view-as-advisor', {
    method: 'POST',
    headers: {
     'Content-Type': 'application/json',
    },
    body: JSON.stringify({ advisorID }),
   });

   if (response.ok) {
    toast({
     title: `Successfully changed to view as advisor ${advisorID}`,
    });
    setAdvisorID('');
   } else {
    const error = await response.json();
    toast({
     title: `Error changing advisor view`,
     description: error.message,
     variant: 'destructive',
    });
   }
  } catch (error) {
   toast({
    title: `Error changing advisor view`,
    description: error.message,
    variant: 'destructive',
   });
  }
  setIsLoading(false);
 };

 return isLoading ? (
  <div>Loading...</div>
 ) : (
  <div className="flex flex-col border rounded-2xl bg-slate-100 p-4 space-y-4 basis-1/2">
   <h1 className="text-xl font-bold">View as Advisor</h1>
   <p>Enter an Advisor ID to view the application as that advisor</p>
   <input
    type="text"
    className="px-2 py-1 rounded"
    value={advisorID}
    onChange={e => setAdvisorID(e.target.value)}
    placeholder="Advisor ID"
   />
   <button
    className="bg-purple-500 p-2 rounded-2xl text-white hover:bg-purple-600 transition-colors"
    onClick={handleViewAsAdvisor}
   >
    View as Advisor
   </button>
  </div>
 );
};

const App = () => {
 const currentUrl = window.location.href;
 const navigate = useNavigate();

 useEffect(() => {
  if (
   !currentUrl.includes('development.whatins.sg') &&
   !currentUrl.includes('localhost') &&
   !currentUrl.includes('http://127.0.0.1:5000/')
  ) {
   navigate('/advisor/profile');
  }
 }, [currentUrl, navigate]);

 if (
  currentUrl.includes('development.whatins.sg') ||
  currentUrl.includes('localhost') ||
  currentUrl.includes('http://127.0.0.1:5000/')
 ) {
  return (
   <div className="flex flex-col gap-4 mx-auto">
    <div className="flex flex-row gap-4 mx-auto">
     <DeleteComponent />
     <AssignAdvisorsComponent />
     <ViewAsAdvisorComponent />
    </div>
    <AddComponent />
    <AgeDistribution />
   </div>
  );
 }

 return <div className="flex flex-row gap-4 mx-auto max-w-2xl"></div>;
};

const AgeDistribution = () => {
 const [ageData, setAgeData] = useState([]);

 useEffect(() => {
  const fetchData = async () => {
   try {
    const response = await fetch('/api/get-prospect', {
     method: 'POST',
     headers: {
      'Content-Type': 'application/json',
     },
    });
    if (!response.ok) {
     throw new Error('Network response was not ok');
    }
    const data = await response.json();
    console.log(data);
    setAgeData(data);
   } catch (error) {
    console.error('Error fetching age distribution data:', error);
   }
  };

  fetchData();
 }, []);

 return (
  <div>
   <h2>Age Distribution of Prospects</h2>
   <BarChart width={600} height={300} data={ageData}>
    <XAxis dataKey="age" />
    <YAxis />
    <Tooltip />
    <CartesianGrid stroke="#f5f5f5" />
    <Bar dataKey="count" fill="#8884d8" />
   </BarChart>
  </div>
 );
};

export default App;

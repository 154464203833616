import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useToast } from "../components/ui/use-toast"
import Loading from "../components/GIF"
import { reportError } from '../components/reportError';
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, DialogFooter } from "../components/ui/dialog";
import { Button } from "../components/ui/button";
import { format, addDays, isBefore } from 'date-fns';

declare global {
  interface Window {
    dataLayer?: any[];
  }
}

function formatDate(dateString) {
  const nextProspectDate = new Date(dateString);
  return `${nextProspectDate.getUTCDate().toString().padStart(2, '0')}/${(nextProspectDate.getUTCMonth() + 1).toString().padStart(2, '0')}/${nextProspectDate.getUTCFullYear()}`;
}


const AdvisorDashboard = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [advisor, setAdvisor] = useState([]);
  const location = useLocation();
  const [selectedAdvisorIndex, setSelectedAdvisorIndex] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [companies, setCompanies] = useState<{ id: string; fields: { Name: string; } }[]>([]);
  const { toast } = useToast()
  const [isWaitlisted, setIsWaitlisted] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [isPauseDialogOpen, setIsPauseDialogOpen] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [downloadingPaymentId, setDownloadingPaymentId] = useState<string | null>(null);


  const handlePauseToggle = async (pause: boolean) => {
    try {
      const response = await fetch('/api/advisor-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'pause', pause }),
      });

      if (response.ok) {
        setIsPaused(pause);
        toast({
          title: 'Success',
          description: pause ? 'Leads paused successfully' : 'Leads resumed successfully',
          variant: 'default',
        });
      } else {
        throw new Error('Failed to update pause status');
      }
    } catch (error) {
      console.error('Error updating pause status:', error);
      reportError({
        message: 'Error updating pause status',
        url: window.location.href,
        function: 'handlePauseToggle',
        componentName: 'AdvisorDashboard',
        stackTrace: error.stack
      });
      toast({
        title: 'Error',
        description: 'Failed to update pause status. Please try again later.',
        variant: 'destructive',
      });
    }
  };


  const handleCompanyChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCompany(event.target.value);
  };

  const handleJoinWaitlist = async () => {
    try {
      const response = await fetch('/api/advisor-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'joinWaitlist' }),
      });

      if (response.ok) {
        setIsWaitlisted(true);
        toast({
          title: 'Success',
          description: 'You have been added to the waitlist.',
          variant: 'default',
        });
      } else {
        throw new Error('Failed to join waitlist');
      }
    } catch (error) {
      reportError({
        message: 'Error joining waitlist',
        url: window.location.href,
        function: 'handleJoinWaitlist',
        componentName: 'AdvisorDashboard',
        stackTrace: error.stack
      });
      toast({
        title: 'Error',
        description: 'Failed to join waitlist. Please try again later.',
        variant: 'destructive',
      });
    }
  };

  const handleDropWaitlist = async () => {
    try {
      const response = await fetch('/api/advisor-profile', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ action: 'dropWaitlist' }),
      });

      if (response.ok) {
        setIsWaitlisted(false);
        toast({
          title: 'Success',
          description: 'You have been removed from the waitlist.',
          variant: 'default',
        });
      } else {
        throw new Error('Failed to drop from waitlist');
      }
    } catch (error) {
      reportError({
        message: 'Error dropping from waitlist',
        url: window.location.href,
        function: 'handleDropWaitlist',
        componentName: 'AdvisorDashboard',
        stackTrace: error.stack
      });
      toast({
        title: 'Error',
        description: 'Failed to drop from waitlist. Please try again later.',
        variant: 'destructive',
      });
    }
  };

  const downloadReceipt = async (paymentId: string) => {
    setDownloadingPaymentId(paymentId);
    try {
      const response = await fetch('/api/generate-receipt', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ paymentId }),
      });

      if (!response.ok) throw new Error('Failed to generate receipt');

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `receipt-${paymentId}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      toast({ title: "Receipt downloaded successfully" });
    } catch (error) {
      console.error('Error downloading receipt:', error);
      toast({ title: "Failed to download receipt. Please email us to get a copy.", variant: "destructive" });
    } finally {
      setDownloadingPaymentId(null);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/advisor-profile`)
      .then(response => {
        if (!response.ok) {
          window.location.href = '/advisor-login';
          return;
        }
        return response.json();
      })
      .then(data => {
        setData(data.advisors);
        setCompanies(data.companies || []);
        setAdvisor(data.advisor || {});  // Initialize as an empty object if null
        setIsLoading(false);
        setDataLoaded(true);  // Set this to true when data is loaded
        setIsPaused(data.advisor?.fields?.['Pause?'] || false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        reportError({
          message: 'Error fetching advisor profile data',
          url: window.location.href,
          function: 'useEffect (fetch advisor profile)',
          componentName: 'AdvisorDashboard',
          stackTrace: error.stack
        });
        setIsLoading(false);
      });
  }, []);

  if (isLoading) {
    return <Loading src="loading.mp4" />;
  }

  if (!dataLoaded) {
    return null;  // or return a loading indicator
  }

  const pauseDate = advisor['fields']['Pause Date'] ? new Date(advisor['fields']['Pause Date']) : null;
  const canUnpause = pauseDate ? !isBefore(new Date(), pauseDate) : true;

  const handleProspectPurchase = (e: React.MouseEvent<HTMLButtonElement>, prospects: number, price: number) => {
    // Prevent default form submission
    e.preventDefault();

    // Capture the button and form before the timeout
    const button = e.currentTarget;
    const form = button.closest('form');

    // Check if dataLayer is available
    if (typeof window !== 'undefined' && window.dataLayer) {
      try {
        window.dataLayer.push({
          event: 'advisor_initiate_purchase',
          purchaseAmount: price,
          prospectCount: prospects,
          userName: advisor['fields']?.Name || '',
          userEmail: advisor['fields']?.Email || ''
        });
      } catch (error) {
        reportError({
          message: 'Error pushing to dataLayer',
          url: window.location.href,
          function: 'handleProspectPurchase',
          componentName: 'AdvisorDashboard',
          additionalInfo: { userName: advisor['fields']?.Name || '', userEmail: advisor['fields']?.Email || '' },
          stackTrace: error.stack
        });
      }
    } else {
      console.log("dataLayer not available");
    }

    // Submit the form after a short delay to ensure the dataLayer push is completed
    setTimeout(() => {
      if (form instanceof HTMLFormElement) {
        const chargeInput = form.querySelector('#charge') as HTMLInputElement;
        if (chargeInput) {
          chargeInput.value = price.toString();
        }
        const prospectsInput = form.querySelector('#prospects') as HTMLInputElement;
        if (prospectsInput) {
          prospectsInput.value = prospects.toString();
        }
        form.submit();
      } else {
        console.error('Form not found');
        // Fallback: redirect to the form action URL if available
        const formAction = button.getAttribute('formaction');
        if (formAction) {
          window.location.href = `${formAction}?prospects=${prospects}&charge=${price}`;
        } else {
          console.error('Unable to submit form or redirect');
        }
      }
    }, 100);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData(e.target);

    try {
      const response = await fetch('/api/advisor-profile', {
        method: 'POST',
        body: formData,
      });
      if (!response.ok) {
        toast({
          title: 'Error',
          description: 'Failed to update profile. Please check that all fields are filled.',
          variant: 'destructive',
        })
      }
      toast({
        title: 'Success',
        description: 'Your profile has been updated.',
        variant: 'default',
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      reportError({
        message: 'Error updating advisor profile',
        url: window.location.href,
        function: 'handleSubmit',
        componentName: 'AdvisorDashboard',
        stackTrace: error.stack
      });
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading src="loading.mp4" />
  }


  return (
    <div className='bg-white-1'>
      {isLoading && <Loading src="loading.mp4" />}

      <div className='flex flex-col md:max-w-screen-2xl md:mx-auto'>
        <div className='flex flex-col mx-4 py-8'>
          <div className='flex flex-col md:w-1/3 mx-4 md:mx-auto py-8'>
            <h1 className='primary-h3 text-green-1 text-center'>Welcome, {advisor['fields']['Name']}</h1>
          </div>
          <div className='flex flex-col md:flex-row  mx-auto w-full gap-4 md:gap-8 md:p-4 md:p-8 md:border rounded-2xl md:px-4'>
            <div id="col-1" className="space-y-8 col-span-1 order-last md:order-first">
              <h2 className="text-h5 text-green-1 font-bold capitalize">Your Profile</h2>
              <form onSubmit={handleSubmit}>
                <div className="grid md:grid-cols-2 md:gap-6">
                  <div className="relative z-0 w-full mb-6 group">
                    <label htmlFor="full-name" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Full Name</label>
                    <input type="text" name="full-name" id="full-name" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={advisor['fields']['Name']} required />
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <label htmlFor="location" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Where are you based?</label>
                    <select required id="location" name="location" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500">
                      <option value={advisor['fields']['Where are you based?']}>{advisor['fields']['Where are you based?']}</option>
                      <option value="Singapore">Singapore</option>
                      <option value="Singapore">Malaysia</option>
                      <option value="Singapore">Other</option>
                    </select>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <label htmlFor="company" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Insurance Company</label>
                    <select
                      id="company"
                      name="company"
                      value={selectedCompany} // Use the selectedCompany state to control the selection
                      onChange={handleCompanyChange} // Set the selectedCompany state based on user selection
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    >
                      <option value={advisor['fields']['CompanyID']}>{advisor['fields']['Company Name']}</option>
                      {companies.map((company, index) => (
                        company.id !== advisor['fields']['CompanyID'] && (
                          <option key={company.id} value={company.id}>{company.fields.Name}</option>
                        )
                      ))}
                      <option value="Other">Other</option>
                    </select>
                    <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">If your company is not there, select others and enter it as part of team name.</p>
                  </div>
                  <div className="relative z-0 w-full mb-6 group">
                    <label htmlFor="team" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Team/Agency Name</label>
                    <input type="text" name="team" id="team" className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " defaultValue={advisor['fields']['Team']} />
                  </div>
                </div>
                <div className="mb-6">
                  <label htmlFor="contact_url" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Contact URL</label>
                  <input type="url" id="contact_url" name="contact_url" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" defaultValue={advisor['fields']['Contact url']} />
                  <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">This will show next to your name on their dashboard.</p>
                </div>
                <div className="mb-6">
                  <label htmlFor="email" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Email</label>
                  <input type="email" id="email" name="email" aria-describedby="helper-text-explanation" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={advisor['fields']['Email']} />
                  <p id="helper-text-explanation" className="mt-2 text-sm text-gray-500 dark:text-gray-400">This is the email we'll cc you in to prospects and is disabled by default.<br /> If you need to change it, please email us.</p>
                </div>
                {/* <div className="mb-6">
      <label htmlFor="bio" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Your Bio</label>
      <textarea id="bio" name="bio" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="What they see about you when they go to your page">{advisor['fields']['bio']}</textarea>
        </div> 
        <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor="profile_picture">Profile Picture</label>
      <input className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400" id="profile_picture" name="profile_picture" type="file" accept="image/*"/>
        </div>*/}
                <button type="submit" className="primary-button1">Update my profile</button>
              </form>
            </div>
            <div id="col-2" className="space-y-8 divide-y-2 col-span-1">
              {!advisor['fields']['Telegram ID'] && (
                <div id="telegram" className="p-4 space-y-2">
                  <h2 className="font-bold text-xl captalize">Connect To Whatins Telegram Bot</h2>
                  <p>We have created a bot that will allow you to talk to your prospects. In order for it to work, we will need you to connect to our bot.</p>
                  <p>Connect to telegram bot</p>
                  <div className="flex gap-x-8 gap-y-4">
                    <a href={`https://t.me/whatins_bot?start=${advisor['id']}`} className="inline-block rounded-lg bg-indigo-400 px-5 py-3 text-sm font-medium text-white capitalize">Connect to telegram bot</a>
                  </div>
                </div>
              )}
              <div id="quota" className="grid p-4 space-y-3">
                <h2 className="text-h5 text-green-1 font-bold capitalize">Prospect Quota Status</h2>
                <div className="relative flex flex-col flex-grow space-y-2">
                  <div className="flex justify-between">
                    <div className="font-semibold capitalize">total prospect credits:</div>
                    <div className="font-normal">{advisor['fields']['Guaranteed Leads']}</div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold capitalize">prospects delivered to date:</div>
                    <div className="font-normal">{advisor['fields']['Total Offered']}</div>
                  </div>
                  <div className="flex justify-between">
                    <div className="font-semibold capitalize"># Prospects owed:</div>
                    <div className="font-normal">{advisor['fields']['Total Leads Owed']}</div>
                  </div>
                </div>
                <div id="payment-options">
                  {advisor['fields']['CompanyID'] ? (
                    <div>
                      {(() => {
                        const totalPayingAdvisors = companies.reduce((sum, company) => sum + (company.fields['Paying Advisors'] || 0), 0);
                        const maxAdvisorsPerCompany = Math.floor(totalPayingAdvisors * 0.2);
                        const currentCompany = companies.find(company => company.id === advisor['fields']['CompanyID'][0]);
                        const isAtLimit = currentCompany && currentCompany.fields['Paying Advisors'] >= maxAdvisorsPerCompany;
                        const isPayingMember = advisor['fields']['Paying Member'] === 1;

                        if (isPayingMember || !isAtLimit) {
                          return (
                            <form action="/stripe-prospect-quota" method="POST" className="space-y-2">
                              <input type="hidden" id="charge" name="charge" />
                              <input type="hidden" id="prospects" name="prospects" />
                              <input type="hidden" id="success_redirect" name="success_redirect" value="topup=True" />
                              <input type="hidden" id="fail_redirect" name="fail_redirect" value="topup=False" />
                              <div className="flex flex-col gap-x-8 gap-y-4">
                                <div className='flex flex-col md:flex-row gap-x-8 gap-y-4'>
                                  <button
                                    id="prospect-purchase-10"
                                    type="submit"
                                    className="primary-button1 capitalize"
                                    onClick={(e) => handleProspectPurchase(e, 10, 200)}
                                  >
                                    Get 10 more prospects for $200
                                  </button>
                                  <button
                                    id="prospect-purchase-100"
                                    type="submit"
                                    className="primary-button1 capitalize"
                                    onClick={(e) => handleProspectPurchase(e, 100, 1500)}
                                  >
                                    Get 100 more prospects for $1500<br />(25% off)
                                  </button>
                                </div>
                                <p>Please <a href="/advisor/onboarding" className="primary-link">go through the onboarding page here</a> before you purchase as we will not offer any refunds.</p>
                              </div>
                            </form>
                          );
                        } else {
                          return (
                            <div>
                              <h2 className="text-h5 text-green-1 font-bold capitalize">Company Quota Reached</h2>


                              {isWaitlisted ? (
                                <div>
                                  <p className="mb-2">You are already on the waitlist. We will notify you once a slot opens up. Once that happens you will have 5 business days to make the purchase or we will assume that you are no longer interested and will drop you from the list.</p>
                                  <button
                                    onClick={handleDropWaitlist}
                                    className="primary-button1 capitalize"
                                  >
                                    Drop from Waitlist
                                  </button>
                                </div>
                              ) : (
                                <div>
                                  <p className='text-sm md:text-base'>In order to ensure that all advisors have a good experience, we will limit the number of advisors from one single company so that they are not competing among themselves. Additional spots will be released as we are consistently bringing on more prospects than the current advisor pool can handle.</p>
                                  <button
                                    onClick={handleJoinWaitlist}
                                    className="primary-button1 capitalize"
                                  >
                                    Join Waitlist
                                  </button>
                                </div>
                              )}
                            </div>
                          );
                        }
                      })()}
                    </div>
                  ) : (
                    <div>
                      <h2 className="text-h5 text-green-1 font-bold capitalize"> ⚠️ Fill out your profile before you can purchase</h2>
                      <form action="/stripe-prospect-quota" method="POST" className="space-y-2">
                        <input type="hidden" id="charge" name="charge" />
                        <input type="hidden" id="success_redirect" name="success_redirect" value="topup=True" />
                        <input type="hidden" id="fail_redirect" name="fail_redirect" value="topup=False" />
                        <div className="flex gap-x-8 gap-y-4">
                          <button disabled id="prospect-purchase" className="primary-button1 capitalize"><span className='line-through'>Get 10 more prospects for $200</span></button>
                          <p>Please fill out your profile, including "Insurance Company" and "Team/Agency Name" before you can purchase.</p>
                        </div>
                      </form>
                    </div>
                  )}
                </div>
              </div>
              {advisor['fields']['Last Payment'] && (
                <div id="payment-history" className="p-4 space-y-2">
                  <h2 className="text-h5 text-green-1 font-bold capitalize">Payment History</h2>
                  <ul className="space-y-2">
                    {JSON.parse(advisor['fields']['Last Payment']).map((payment) => (
                      <li key={payment.id} className="flex justify-between items-center">
                        <span>
                          {format(new Date(payment.created * 1000), 'dd/MM/yyyy')} - ${(payment.amount_total / 100).toFixed(2)} {payment.currency.toUpperCase()}
                        </span>
                        <button
                          onClick={() => downloadReceipt(payment.id)}
                          disabled={downloadingPaymentId === payment.id}
                          className="primary-button1 text-sm"
                        >
                          {downloadingPaymentId === payment.id ? 'Downloading...' : 'Download Receipt'}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <div id="flow" className="p-4 space-y-2">
                <div className="relative flex flex-col flex-grow space-y-2">
                  <div className="flex flex-col md:flex-row justify-between">
                    <div className="font-semibold capitalize">Earliest day you'll be able to get your next prospect:</div>
                    <div className="font-normal">{formatDate(advisor['fields']['Next Prospect'])}</div>
                  </div>
                </div>
                <h2 className="text-h5 text-green-1 font-bold capitalize">Manage Prospect Flow</h2>
                <p className='text-sm md:text-base'>How many days between being randomly selected for a prospect would you like to have before you're back on the list?</p>
                <form method="POST" action="/prospectManagement" className="space-y-2">
                  <input type="number" id="days" name="days" disabled value={advisor['fields']['Days Between Prospect']} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
                  <p className='text-sm'>We are defaulting all advisors to 1 prospect every 3 days until we are better able to manage prospect flow and fair distribution.</p>

                  {/* <div className="flex gap-x-8 gap-y-4">
                    <button id="days-between" type="submit" className="inline-block rounded-lg bg-indigo-400 px-5 py-3 text-sm font-medium text-white capitalize">Update days between prospects</button>
                </div> */}
                </form>
              </div>
              <div id="holiday" className="p-4 space-y-2">
                <h2 className="text-h5 text-green-1 font-bold capitalize">Going On Holiday?</h2>
                <p className='pb-4'>Pause getting leads while you're on holiday to avoid using your skip quota. You can unpause it when you are back.</p>

                {!isPaused ? (
                  <Dialog open={isPauseDialogOpen} onOpenChange={setIsPauseDialogOpen}>
                    <DialogTrigger asChild>
                      <Button className="primary-button1 capitalize">Pause Getting Leads</Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>Are you sure you want to pause?</DialogTitle>
                        <DialogDescription>
                          You will be unable to unpause until {format(addDays(new Date(), 30), 'MMMM d, yyyy')}.
                          This ensures we can service all paying advisors with the number of prospects we promised.
                        </DialogDescription>
                      </DialogHeader>
                      <DialogFooter>
                        <Button variant="secondary" onClick={() => setIsPauseDialogOpen(false)}>Cancel</Button>
                        <Button variant='primary' onClick={() => handlePauseToggle(true)}>OK</Button>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                ) : (
                  canUnpause ? (
                    <Button onClick={() => handlePauseToggle(false)} className="primary-button1 capitalize">Resume Getting Leads</Button>
                  ) : (
                    <Button disabled className="primary-button1 capitalize">
                      Resume Getting Leads (Available after {format(pauseDate!, 'MMMM d, yyyy')})
                    </Button>
                  )
                )}
              </div>
              <div id="support" className="p-4 space-y-2">
                <h2 className="text-h5 text-green-1 font-bold capitalize">Need help with something?</h2>
                <p>We are still working on improving this page, so if something is missing, drop us an email at admin@whatins.sg</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const AdvisorResourcesPage: React.FC = () => {
  return (
    <div className='bg-white-1 px-4'>
      <div className="advisor-resources  md:max-w-screen-2xl md:mx-auto">
        <div className="flex flex-col pb-12">
          <section id="introduction" className="md:pt-8 md:pb-6">
            <h3 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4">
              Introduction
            </h3>
            <p className="px-4 pb-6">
              Welcome to Whatins, a revolutionary platform for insurance lead generation. As a co-founder, I'm excited to explain how we're transforming the way advisors like you connect with quality leads. Our goal is to provide you with valuable prospects at a fraction of the cost of traditional methods.
            </p>
            <div className="px-4 pb-6">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/On-1QeL5Uv8"
                title="Whatins Onboarding"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen>
              </iframe>
            </div>
          </section>


          <section id="understanding-process" className="md:pt-8 md:pb-6">
            <h3 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4">
              The Challenge of Lead Generation
            </h3>
            <p className="px-4 pb-6">
              We understand that lead generation in the insurance industry can be challenging and costly. Traditional methods often involve purchasing outdated lists or investing heavily in advertising.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Common Lead Generation Challenges
                </h4>
                <ul className="list-disc pl-5">
                  <li>Purchasing lists of phone numbers (often outdated or on Do Not Call lists)</li>
                  <li>Hiring expensive agencies to build your brand and run ads</li>
                  <li>Learning to run your own ads (which can be costly and time-consuming)</li>
                </ul>
              </div>
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  How Whatins Solves These Issues
                </h4>
                <ul className="list-disc pl-5">
                  <li>We allocate each lead to five different advisors, spreading the cost</li>
                  <li>Our focus is on helping you get that crucial "first date" with prospects</li>
                  <li>We thoroughly vet prospects before assigning them to advisors</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="understanding-process" className="md:pt-8 md:pb-6">
            <h3 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4">
              Understanding Whatins' Process
            </h3>
            <p className="px-4 pb-6">
              Knowing how Whatins operates can help you better navigate the platform and set appropriate expectations.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Fair Lead Distribution
                </h4>
                <ul className="list-disc pl-5">
                  <li>Each lead is matched with 5 different advisors.</li>
                  <li>At most one other advisor will be from the same company to reduce internal competition.</li>
                  <li>This gives you a fair chance while ensuring prospects get diverse perspectives.</li>
                </ul>
              </div>
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Lead Quality Assurance
                </h4>
                <ul className="list-disc pl-5">
                  <li>We conduct thorough filtering for prospects to ensure quality.</li>
                  <li>We verify potential advisors against the MAS database to ensure prospects are not in the industry.</li>
                  <li>Our vetting process ensures you receive genuine, interested leads.</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="understanding-your-role" className="md:pt-8 md:pb-6">
            <h3 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4">
              Understanding Your Role
            </h3>
            <p className="px-4 pb-6">
              As an advisor on Whatins, your primary goal is to build trust and provide value to prospects. Let's explore the key aspects of your role on the platform.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Build Trust and Rapport
                </h4>
                <ul className="list-disc pl-5">
                  <li>Focus on demonstrating your expertise and thought process</li>
                  <li>Show how you can help with their specific situation</li>
                  <li>Aim to build a relationship, not close a sale immediately</li>
                </ul>
              </div>
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Provide Value First
                </h4>
                <ul className="list-disc pl-5">
                  <li>Use limited information as an opportunity to showcase problem-solving skills</li>
                  <li>Demonstrate how you think through their situation</li>
                  <li>Position yourself as an asset to their financial life</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="best-practices" className="md:pt-8 md:pb-6">
            <h3 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4">
              Best Practices for Engaging Prospects
            </h3>
            <p className="px-4 pb-6">
              Engaging effectively with prospects is key to your success on Whatins. Here are some best practices to help you convert more leads into clients.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Keep Conversations on Whatins
                </h4>
                <ul className="list-disc pl-5">
                  <li>Build rapport through the platform first</li>
                  <li>Avoid rushing to take conversations offline</li>
                  <li>Understand prospects may be earlier in their buying journey</li>
                </ul>
              </div>
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Respond Promptly
                </h4>
                <ul className="list-disc pl-5">
                  <li>Aim to respond within 2 hours, maximum 24 hours</li>
                  <li>Quick responses show professionalism and eagerness to help</li>
                </ul>
              </div>
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Personalize Your Approach
                </h4>
                <ul className="list-disc pl-5">
                  <li>Use information from the prospect's questionnaire</li>
                  <li>Tailor your messages to their unique needs</li>
                  <li>Be prepared for a longer nurturing process</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="leveraging-features" className="md:pt-8 md:pb-6">
            <h3 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4">
              Leveraging Whatins Features
            </h3>
            <p className="px-4 pb-6">
              Whatins offers several unique features to help you succeed. Here's how to make the most of them.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-6">
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  "Premiums On Us" Campaign
                </h4>
                <ul className="list-disc pl-5">
                  <li>Use as a selling point to encourage prospect meetings</li>
                  <li>Explain increased chances of winning with multiple advisor meetings</li>
                  <li>Emphasize eligibility for policies purchased through Whatins advisors</li>
                </ul>
              </div>
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Advisor Dashboard
                </h4>
                <ul className="list-disc pl-5">
                  <li>Access detailed information shared by prospects</li>
                  <li>Use the messaging center to communicate effectively</li>
                  <li>Track your performance and engagement metrics</li>
                </ul>
              </div>
              <div className="rounded-2xl md:border-2 border-gray-200 p-6">
                <h4 className="text-h5 text-green-1 font-bold text-pretty pb-4 capitalize">
                  Ongoing Improvements
                </h4>
                <ul className="list-disc pl-5">
                  <li>We're constantly enhancing the platform based on feedback</li>
                  <li>Future updates may include email tracking and proposal view metrics</li>
                  <li>Your input helps shape the future of Whatins</li>
                </ul>
              </div>
            </div>
          </section>

          <section id="others" className="md:pt-8 md:pb-6 p-2">
            <h3 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4">
              Other things to note
            </h3>
            <p className="pb-6">
              We want to ensure that there are no misunderstandings on what you will or will not get. So here are some things to note:
            </p>
            <ul className="list-disc pl-5">
              <li>You are guaranteed to get an email that we know is real once you submit your proposal to the prospect.</li>
              <li>Phone number is not a required field for our prospects. We are always looking for ways to incentivise them to share it but it is not guaranteed. You will see if the prospect you are assigned provided their phone number on the dashboard.</li>
              <li>We have no control over if and when the prospect responds to you. If our tracking system is down, we will let you know.</li>
            </ul>
            
          </section>

          <section id="conclusion" className="md:pt-8 md:pb-6">
            <h3 className="primary-h3 text-green-1 font-bold tracking-tight leading-none text-pretty pb-4 px-4">
              Conclusion
            </h3>
            <p className="px-4 pb-6">
              Whatins offers a unique opportunity to connect with quality leads at a lower cost. While these leads may require more nurturing, they represent a valuable chance to grow your business. We're committed to supporting your success and welcome your feedback as we continue to evolve our platform.
            </p>
            <p className="px-4 pb-6">
              If you have any questions or need assistance, don't hesitate to reach out to our support team. We're excited to see your achievements on Whatins!
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export const AdvisorPurchase: React.FC = () => {
  const { advisorId } = useParams<{ advisorId: string }>();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const leads = searchParams.get('leads');

  console.log('AdvisorPurchase component rendered', { advisorId, leads });

  useEffect(() => {
    if (advisorId && leads) {
      console.log('Fetching checkout URL', { advisorId, leads });
      fetch(`/api/advisor/purchase/${advisorId}?leads=${leads}`)
        .then(response => {
          console.log('API response received', response);
          if (response.ok) {
            return response.json();
          }
          throw new Error('Network response was not ok');
        })
        .then(data => {
          console.log('Checkout URL received', data);
          if (data.checkout_url) {
            window.location.href = data.checkout_url;
          } else {
            console.error('Error:', data.error);
            // Handle error (e.g., show error message to user)
          }
        })
        .catch(error => {
          console.error('Error:', error);
          // Handle error (e.g., show error message to user)
        });
    }
  }, [advisorId, leads]);

  return (
    <div>
      <h1>Preparing your purchase...</h1>
      {/* You can add a loading spinner here */}
    </div>
  );
};

export default AdvisorDashboard;
import React, { useState, useEffect } from 'react';

interface ImageProps {
  src: string;
  alt?: string;
  className?: string;
  imgClass?: string;
  dimension?: string;
}

const Image: React.FC<ImageProps> = ({ src, alt, className, imgClass, dimension }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const imagePath = `/images/${src}`;
  const defaultClasses = "w-full h-full object-contain rounded-2xl";
  const defaultImgClass = "rounded-2xl";

  const squareStyle = dimension === 'square' ? {
    objectFit: 'cover',
    objectPosition: 'center',
    aspectRatio: '1 / 1',
  } as React.CSSProperties : {};

  useEffect(() => {
    const img = new window.Image();
    img.onload = () => setIsLoaded(true);
    img.src = imagePath;
  }, [imagePath]);

  return (
    <div className={className || defaultClasses}>
      {!isLoaded && (
        <div className="w-full h-full bg-gray-200 animate-pulse rounded-2xl" style={squareStyle} />
      )}
      <img
        src={imagePath}
        alt={alt || "whatins image"}
        className={`${imgClass || defaultImgClass} ${isLoaded ? 'opacity-100' : 'opacity-0'}`}
        loading="lazy"
        style={{ ...squareStyle, transition: 'opacity 0.3s' }}
        onLoad={() => setIsLoaded(true)}
      />
    </div>
  );
};

export default Image;
import Image from './image'
import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-white-1 py-16 text-gray-500 text-sm">
      <div className="max-w-screen-xl mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4 uppercase">Explore</h3>
            <ul className="space-y-2">
              {/* <li>About</li> */}
              <li><a href='https://www.whatins.sg/lp/get-proposals' className='primary-link'>Get personalised suggestions (free)</a></li>
              <li><a href='https://www.whatins.sg/explore' className='primary-link'>Other people's insurance suggestions</a></li>
              {/* <li>Blog</li> */}
              {/* <li>Affiliate Program</li>
              <li>Get Coupon</li> */}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 uppercase">Best series</h3>
            <ul className="space-y-2">
              <li><a href='https://www.whatins.sg/life-insurance/best-term-life-insurance-review-in-singapore' className='primary-link'>Best term life policies</a></li>
              <li><a href='https://www.whatins.sg/life-insurance/best-critical-illness-insurance-review-in-singapore' className='primary-link'>Best critical illness plans</a></li>
              <li><a href='https://www.whatins.sg/life-insurance/best-hospital-(isp)-insurance-review-in-singapore' className='primary-link'>Best hospitalisation plan</a></li>
              <li><a href='https://www.whatins.sg/life-insurance/best-disability-insurance-review-in-singapore' className='primary-link'>Best disability plans</a></li>
              <li><a href='https://www.whatins.sg/life-insurance/best-personal-accident-insurance-review-in-singapore' className='primary-link'>Best personal accident plan</a></li>
              <li><a href='https://www.whatins.sg/life-insurance/best-whole-life-insurance-review-in-singapore' className='primary-link'>Best whole life plans</a></li>
              <li><a href='https://www.whatins.sg/life-insurance/best-maternity-insurance-review-in-singapore' className='primary-link'>Best maternity plans</a></li>
              <li><a href='https://www.whatins.sg/life-insurance/best-endowment-savings-plan-review-in-singapore' className='primary-link'>Best Endowment</a></li>
              <li><a href='https://www.whatins.sg/life-insurance/best-investment-linked-plan-(ilp)-review-in-singapore' className='primary-link'>Best Investment Linked Plan</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 uppercase">Explore by Brand</h3>
            <ul className="space-y-2">
            <li><a href='https://www.whatins.sg/insurance-brand/aia' className='primary-link'>AIA</a></li>
              <li><a href='https://www.whatins.sg/insurance-brand/fwd' className='primary-link'>FWD</a></li>
              <li><a href='https://www.whatins.sg/insurance-brand/great-eastern' className='primary-link'>Great Eastern</a></li>
              <li><a href='https://www.whatins.sg/insurance-brand/hsbc' className='primary-link'>HSBC</a></li>
              <li><a href='https://www.whatins.sg/insurance-brand/ntuc-income' className='primary-link'>Income</a></li>
              <li><a href='https://www.whatins.sg/insurance-brand/manulife' className='primary-link'>Manulife</a></li>
              <li><a href='https://www.whatins.sg/insurance-brand/prudential' className='primary-link'>Prudential</a></li>
              <li><a href='https://www.whatins.sg/insurance-brand/singlife' className='primary-link'>Singlife</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 uppercase">advisors</h3>
            <ul className="space-y-2">
              <li><a href='/advisor/login' className='primary-link'>Login</a></li>
              <li><a href='https://www.whatins.sg/lp/advisor' className='primary-link'>Register</a></li>
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4 uppercase">About</h3>
            <ul className="space-y-2">
            <li><a href='https://www.whatins.sg/contact' className='primary-link'>Contact</a></li>
            <li><a href='https://www.whatins.sg/terms' className='primary-link'>Terms</a></li>
              {/* <li>Privacy Policy</li>
              <li>Terms & Conditions</li>
              <li>EULA</li> */}
            </ul>
          </div>
        </div>
        <div className="mt-8 text-center">
          <div className="mt-4 flex justify-center space-x-4">
          <a href="https://www.whatins.sg/"><Image src="whatins-logo.svg" alt="Whatins logo" className="dark:hidden" imgClass='h-12'/><Image src="whatins-logo-dark-mode.svg" alt="Whatins logo" className=" hidden dark:block" imgClass='h-12'/></a>
          </div>
          <p className="mt-2">© 2024 Whatins. All rights reserved.</p>
          <div className="mt-4 flex justify-center space-x-4">
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <i className="fab fa-twitter"></i>
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="#" className="text-gray-400 hover:text-gray-500">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { buttonVariants } from "../components/ui/button";
import Image from "./image";
import Helmet from "react-helmet";
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuIndicator,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  NavigationMenuViewport,
} from "../components/ui/navigation-menu";
import { Separator } from "../components/ui/separator";

import {
  Menubar,
  MenubarContent,
  MenubarItem,
  MenubarMenu,
  MenubarSeparator,
  MenubarShortcut,
  MenubarTrigger,
} from "../components/ui/menubar";

import { navigationMenuTriggerStyle } from "../components/ui/navigation-menu";

const Nav = () => {
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  // Function to toggle dropdown visibility
  const toggleMobileDropdown = () => {
    setIsMobileDropdownOpen(!isMobileDropdownOpen);
  };

  // Initialize dropdown state on component mount
  useEffect(() => {
    setIsMobileDropdownOpen(false); // Set initial state of dropdown
  }, []);

  const cn = (...classes: string[]) => classes.filter(Boolean).join(" ");

  return (
    <nav className=" px-4 py-6 relative left-0 right-0 top-0 z-50 bg-white-1">
      <div
        id="desktop"
        className="mx-auto px-4 sm:px-8 lg:px-12 hidden md:flex max-w-screen-xl py-1.5  flex-row justify-between"
      >
        <div className="flex items-center">
          <div className="md:flex items-center md:gap-12 md:pr-12">
            <a href="https://www.whatins.sg/">
              <Image
                src="whatins-logo.svg"
                alt="Whatins logo"
                className="dark:hidden"
                imgClass="h-4"
              />
              <Image
                src="whatins-logo-dark-mode.png"
                alt="Whatins logo"
                className=" hidden dark:block"
                imgClass="h-4"
              />
            </a>
          </div>
          <div className="md:flex md:items-center md:gap-12">
            <div aria-label="Global" className="hidden md:block">
              <ul className="flex items-center gap-6 text-sm">
                <li>
                  <a
                    className="block px-4 py-2 rounded-md text-gray-700 hover:bg-green-2/80  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-base"
                    href="https://www.whatins.sg/explore-insurance"
                  >
                    Explore insurance
                  </a>
                </li>
                <li>
                  <a
                    className="block px-4 py-2 rounded-md text-gray-700 hover:bg-green-2/80  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-base"
                    href="https://www.whatins.sg/explore"
                  >
                    Community suggestions
                  </a>
                </li>
                {/* <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-green-2/80  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-base" href="https://www.whatins.sg/explore">How it works</a>
              </li>
              <li>
                <a className="block px-4 rounded-full text-gray-700 hover:bg-green-2/80  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-base" href="https://www.whatins.sg/explore">FAQ</a>
              </li> */}
                <NavigationMenu>
                  <NavigationMenuList>
                    <NavigationMenuItem>
                      <NavigationMenuTrigger>
                        <p className="block px-4 rounded-full text-gray-700  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-green-2 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-base">
                          Advisor
                        </p>
                      </NavigationMenuTrigger>
                      <NavigationMenuContent>
                        <NavigationMenuLink asChild>
                          <a
                            className="flex select-none flex-col justify-end rounded-md py-2 px-4 no-underline outline-none focus:shadow-md hover:bg-green-2"
                            href="/advisor/login"
                          >
                            <p>Login</p>
                          </a>
                        </NavigationMenuLink>
                        <NavigationMenuLink>
                          <a
                            className="flex select-none flex-col justify-end rounded-md py-2 px-4 no-underline outline-none focus:shadow-md hover:bg-green-2"
                            href="https://www.whatins.sg/lp/advisor"
                          >
                            <p>Register</p>
                          </a>
                        </NavigationMenuLink>
                      </NavigationMenuContent>
                    </NavigationMenuItem>
                  </NavigationMenuList>
                </NavigationMenu>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-center gap-4">
          <a
            className="block px-4 py-2 rounded-md text-gray-700 hover:bg-green-2/80  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700 text-base"
            href="https://www.whatins.sg/login"
          >
            Login
          </a>
          <a
            href="https://www.whatins.sg/lp/get-proposals?source=top-nav"
            className="primary-button1"
          >
            Get your free personalised plans
          </a>
        </div>
      </div>

      <div id="mobile" className="relative block md:hidden w-full">
        <div className="flex justify-between items-center">
          <div className="md:flex md:gap-12">
            <a href="https://www.whatins.sg/">
              <Image
                src="whatins-logo.svg"
                alt="Whatins logo"
                className="w-24"
                imgClass="w-24"
              />
            </a>
          </div>
          <div>
            <a href="https://www.whatins.sg/lp/get-proposals" className="primary-button1">
              Get Advice
            </a>
          </div>
          <div>
            <Menubar>
              <MenubarMenu>
                <MenubarTrigger>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-7 w-7"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M4 6h16M4 12h16M4 18h16"
                    />
                  </svg>
                </MenubarTrigger>
                <MenubarContent>
                  <MenubarItem>
                    <a className="block hover:bg-green-2/80" href="https://www.whatins.sg/explore">
                      Community suggestions
                    </a>
                  </MenubarItem>
                  <MenubarSeparator />
                  <MenubarItem>
                    <a
                      className="block hover:bg-green-2/80"
                      href="https://www.whatins.sg/explore-insurance"
                    >
                      Explore insurance
                    </a>
                  </MenubarItem>
                  <MenubarSeparator />
                  <MenubarItem>
                    <a
                      className="block hover:bg-green-2/80"
                      href="https://www.whatins.sg/lp/advisor"
                    >
                      Register as Advisor
                    </a>
                  </MenubarItem>
                  <MenubarSeparator />
                  <MenubarItem>
                    <a
                      className="block hover:bg-green-2/80"
                      href="/advisor/login"
                    >
                      Advisor Login
                    </a>
                  </MenubarItem>
                  <MenubarSeparator />
                  <MenubarItem>
                    <a className="block hover:bg-green-2/80" href="https://www.whatins.sg/login">
                      Login
                    </a>
                  </MenubarItem>
                  <MenubarSeparator />
                  <MenubarItem>
                    <Link
                      to="/lp/get-proposals?source=mobile-top-nav"
                      className={cn(
                        buttonVariants({ variant: "outline" }),
                        "bg-purple-500 hover:bg-purple-700 text-white font-bold py-2 px-4 rounded w-full"
                      )}
                    >
                      Get your free personalised plans
                    </Link>
                  </MenubarItem>
                </MenubarContent>
              </MenubarMenu>
            </Menubar>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;

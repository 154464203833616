import React, { useEffect, useState, useContext } from "react";
import { withGlobalState } from "./HOC";
import Image from "./image";

const Nav = ({ user, loading, error }) => {
  const [isMobileDropdownOpen, setIsMobileDropdownOpen] = useState(false);
  // Function to toggle dropdown visibility
  const toggleMobileDropdown = () => {
    setIsMobileDropdownOpen(!isMobileDropdownOpen);
  };

  // Initialize dropdown state on component mount
  useEffect(() => {
    setIsMobileDropdownOpen(false); // Set initial state of dropdown
  }, []);

  fetch("/api/check-logged").then((response) => {
    if (response.status === 401) {
      // Redirect to the login page if unauthorized
      window.location.href = "/advisor/login";
      return Promise.reject("Unauthorized");
    }
    return response.json();
  });

  return (
    <nav className=" px-4 py-6 relative left-0 right-0 top-0 z-50 bg-white-1">
      <div
        id="desktop"
        className="mx-auto px-4 sm:px-8 lg:px-12 hidden md:block max-w-screen-xl py-2"
      >
        <div className="flex justify-between items-center">
          <div className="md:flex items-center md:gap-12">
            <a href="/advisor/profile">
              <Image
                src="whatins-logo.svg"
                className="w-24"
                alt="whatins-logo"
                imgClass="w-24"
              />
            </a>
          </div>
          <div className="md:flex md:items-center md:gap-12">
            <div aria-label="Global" className="hidden md:block">
              <ul className="flex items-center gap-6 text-sm">
                <li>
                  <a
                    className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    href="/advisor/onboarding"
                  >
                    Onboarding
                  </a>
                </li>
                <li>
                  <a
                    className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    href="/advisor/prospects"
                  >
                    My Prospects
                  </a>
                </li>
                <li>
                  <a
                    className="block px-4 rounded-full text-gray-700 hover:bg-indigo-100  dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700"
                    href="/advisor/profile"
                  >
                    My profile
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-row items-center gap-4">
            <button onClick={handleLogout} className="primary-button1">
              Logout
            </button>
          </div>
        </div>
      </div>

      <div id="mobile" className="relative block md:hidden w-full">
        <div className="flex justify-between items-center">
          <div className="md:flex md:gap-12">
            <a href="/my/">
              <Image
                src="whatins-logo.svg"
                className="w-24"
                alt="whatins-logo"
                imgClass="w-24"
              />
            </a>
          </div>
          <div>
            <button
              onClick={toggleMobileDropdown}
              className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-7 w-7"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
            <div
              className={`absolute top-full left-0 w-full my-2 pb-4 px-4 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden z-10 ${
                isMobileDropdownOpen ? "block" : "hidden"
              }`}
              id="mobileDropdown"
            >
              <ul className="w-full text-md">
                <li>
                  <a
                    className="block hover:bg-indigo-100 px-4 py-2"
                    href="/advisor/onboarding"
                  >
                    Onboarding
                  </a>
                </li>
                <li>
                  <a
                    className="block hover:bg-indigo-100 px-4 py-2"
                    href="/advisor/prospects"
                  >
                    My Prospects
                  </a>
                </li>
                <li>
                  <a
                    className="block hover:bg-indigo-100 px-4 py-2"
                    href="/advisor/profile"
                  >
                    My Profile
                  </a>
                </li>
              </ul>
              <button onClick={handleLogout} className="primary-button1">
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

const handleLogout = () => {
  fetch("/api/logout", { method: "GET" }) // Adjust the endpoint as necessary
    .then(() => {
      // Redirect to login page or handle UI changes
      window.location.href = "https://whatins.sg"; // Update with your login route
    })
    .catch((error) => console.error("Logout failed:", error));
};

export default withGlobalState(Nav);

import { useState } from "react";
import { Button } from "../components/ui/button";
import { Input } from "../components/ui/input";
import { Label } from "../components/ui/label";
import Image from "../components/image";
import {auth} from "../components/firebase"
import {  signInWithEmailAndPassword, sendPasswordResetEmail   } from 'firebase/auth';
import { NavLink, useNavigate } from 'react-router-dom'
import { useToast } from "../components/ui/use-toast"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../components/ui/dialog"
import Loading from "../components/GIF"

const AdvisorLogin = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false);
     
  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      const idToken = await user.getIdToken();
      const lowercaseEmail = email.toLowerCase();
  
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: lowercaseEmail, idToken }),
      });
  
     if (response.status === 200) {
        window.location.href = '/advisor/profile';
      }
  
      const responseData = await response.json();
      // navigate('/questionnaire/start', { state: { data: responseData } });
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      toast({
        title: 'Error',
        description: error.message,

      });
    }
    setIsLoading(false);
  };
  const resetPassword = (e) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast({ title: '✅ Success', description: 'Password reset email sent. Please check your inbox.' });
      })
      .catch((error) => {
        toast({ title: 'Error', description: error.message });
      });
  };
  

  return (
    <div className="lg:grid lg:min-h-[600px] lg:grid-cols-2 mx-auto py-8 bg-white-1">
      {isLoading && <Loading src = "loading.mp4" />}
      <div className="flex items-center justify-center py-12">
        <div className="mx-4 md:mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Welcome back, Advisor!</h1>
            <p className="text-balance text-muted-foreground">
              Login to your account here and engage with your leads.
            </p>
          </div>
          <form onSubmit={onLogin} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                required
                value={email}
                className="bg-white-2"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="grid gap-2">
              <div className="flex items-center justify-between">
                <Label htmlFor="password">Password</Label>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="link"><span className="primary-link">Forgot your password?</span></Button>
                  </DialogTrigger>
                  <DialogContent className="sm:max-w-md bg-white-1">
                    <DialogHeader>
                      <DialogTitle>Forgot password?</DialogTitle>
                      <DialogDescription>
                      <form onSubmit={resetPassword} className="grid gap-4">
                        Enter your email here and we'll send a link to reset your password.
                        <Input
                          id="email"
                          type="email"
                          placeholder="m@example.com"
                          required
                          value={email}
                          className="bg-white-2"
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        <button type="submit" className="primary-button1 text-green1 font-semibold w-full">Reset Password</button>
                      </form>
                      </DialogDescription>
                    </DialogHeader>
                    
                    <DialogFooter className="justify-between">
                      <DialogClose asChild>
                        <Button type="button" variant="secondary">
                          Close
                        </Button>
                      </DialogClose>
                    </DialogFooter>
                  </DialogContent>
                </Dialog>
              </div>
              <Input
                id="password"
                type="password"
                required
                value={password}
                className="bg-white-2"
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            {/* {error && <p className="text-red-500">{error}</p>} */}
            <Button type="submit" className="w-full primary-button1 text-green1 font-semibold">
              Login
            </Button>
          </form>
          <div className="mt-4 text-center text-sm">
            Don't have an account?{" "}
            <a href="/advisor-register" className="underline">
              Sign up
            </a>
          </div>
        </div>
      </div>
      <div className="hidden lg:block">
        <Image
          src="whatins-login-header.png"
          alt="Image"
        />
      </div>
    </div>
  );
};

const ProspectLogin = () => {
  const [email, setEmail] = useState('');
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false);
     
  const onLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const lowercaseEmail = email.toLowerCase();
  
      const response = await fetch('/api/prospect-login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: lowercaseEmail }),
      });
  
      if (response.status === 200) {
        toast({
          title: '✅ We sent an email',
          description: 'Please check your email to log in to your account',
          duration: 10000,
        });
      } else {
        const errorData = await response.json();
        throw new Error(errorData.message || 'An error occurred');
      }
    } catch (error) {
      console.error('There was a problem with the login process:', error);
      toast({
        title: 'Error',
        description: error.message,
      });
    }
    setIsLoading(false);
  };
  

  return (
    <div className="lg:grid lg:min-h-[600px] lg:grid-cols-2 mx-auto py-8 bg-white-1">
      {isLoading && <Loading src = "loading.mp4" />}
      <div className="flex items-center justify-center py-12">
        <div className="mx-4 md:mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Hi there!</h1>
            <p className="text-balance text-muted-foreground">
              Manage your insurance here.
            </p>
          </div>
          <form onSubmit={onLogin} className="grid gap-4">
            <div className="grid gap-2">
              <Label htmlFor="email">Email</Label>
              <Input
                id="email"
                type="email"
                placeholder="m@example.com"
                required
                value={email}
                className="bg-white-2"
                onChange={(e) => setEmail(e.target.value)}
              />
              <p className="text-xs">Use the email that was used to go through the questionnaire</p>
            </div>
            <Button type="submit" className="w-full primary-button1 text-green1 font-semibold">
              Login
            </Button>
          </form>
          <div className="mt-4 text-center text-sm">
            <p>Are you an advisor?</p>
            <a href="/advisor-login" className="underline">
              Advisor login
            </a>
          </div>
        </div>
      </div>
      <div className="hidden lg:block">
        <Image
          src="whatins-login-header.png"
          alt="Image"
        />
      </div>
    </div>
  );
};

export { AdvisorLogin, ProspectLogin };
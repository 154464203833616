import {
 Sheet,
 SheetContent,
 SheetDescription,
 SheetHeader,
 SheetTitle,
 SheetTrigger,
} from './ui/sheet';
import {
 Accordion,
 AccordionContent,
 AccordionItem,
 AccordionTrigger,
} from './ui/accordion';
import { ScrollArea } from './ui/scroll-area';

interface BlockNumberFAQProps {
 children: React.ReactNode;

 className?: string;
}

export function BlockNumberFAQ({ children, className }: BlockNumberFAQProps) {
 return (
  <Sheet>
   <SheetTrigger asChild>
    <span
     className={className ? className : 'underline text-green-1 cursor-pointer'}
    >
     {children}
    </span>
   </SheetTrigger>
   <SheetContent side="bottom" className="bg-white-1">
    <SheetHeader>
     <SheetTitle>
      <p className="text-center text-green-1 font-semibold">
       Did someone spam you?
      </p>
     </SheetTitle>
     <SheetDescription>
      <div className="w-full p-4 text-start mx-auto">
       <ScrollArea className="h-96 max-w-xl md:mx-auto pr-2" type="always">
        <div className="*:py-1">
         <p>
          We take your experience very seriously. If you feel an advisor is
          spamming you, we want to know about it right away. You can easily
          report this behavior directly from your dashboard. We have a
          zero-tolerance policy for spam and will take immediate action to
          protect you.
         </p>
        </div>
        <Accordion type="single" collapsible className="w-full">
         <AccordionItem value="item-10">
          <AccordionTrigger>
           What happens when I report an advisor for spamming?
          </AccordionTrigger>
          <AccordionContent>
           <p className="pb-2">
            We take every report seriously to ensure your peace of mind. Here's
            what happens:
           </p>
           <ul className="list-disc pl-5">
            <li>We immediately investigate the report.</li>
            <li>
             If confirmed, we disable the advisor's ability to receive new
             prospects for a period of time, impacting their ability to earn.
            </li>
            <li>
             For repeated offenses, we permanently ban the advisor from our
             platform.
            </li>
            <li>
             We may reach out to you for more information to improve our
             anti-spam measures.
            </li>
           </ul>
           <p className="pt-2">
            Your feedback helps us maintain a respectful and professional
            environment for all users.
           </p>
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-11">
          <AccordionTrigger>
           How can I report spam or inappropriate behavior?
          </AccordionTrigger>
          <AccordionContent>
           <p className="pb-2">
            We've made it easy for you to report any concerns:
           </p>
           <ol className="list-decimal pl-5">
            <li>Log into your dashboard.</li>
            <li>Find the advisor in question.</li>
            <li>Click on the "Report" or "Flag" button next to their name.</li>
            <li>
             Select "Spam" as the reason and provide any additional details.
            </li>
            <li>Submit the report.</li>
           </ol>
           <p className="pt-2">
            We'll review your report promptly and take appropriate action to
            protect you and other prospects.
           </p>
          </AccordionContent>
         </AccordionItem>
         <AccordionItem value="item-1">
          <AccordionTrigger>How to block a number on iPhone</AccordionTrigger>
          <AccordionContent>
           <ol>
            <li>Open the Phone app and go to the Recents tab.</li>
            <li>
             Find the number you want to block and tap the (i) icon next to it.
            </li>
            <li>Scroll down and tap "Block this Caller".</li>
            <li>Confirm by tapping "Block Contact".</li>
           </ol>
           <p>
            Alternatively, you can go to Settings &gt; Phone &gt; Blocked
            Contacts, then tap "Add New" to block a number.
           </p>
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-2">
          <AccordionTrigger>How to block a number on Android</AccordionTrigger>
          <AccordionContent>
           <p>
            The process may vary slightly depending on your Android device, but
            generally:
           </p>
           <ol>
            <li>Open the Phone app.</li>
            <li>
             Tap the More options (usually three dots) next to the number you
             want to block.
            </li>
            <li>Select "Block number" or "Add to block list".</li>
           </ol>
           <p>
            You can also usually go to Settings &gt; Calls &gt; Blocked numbers
            to manage your block list.
           </p>
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-3">
          <AccordionTrigger>How to block a number on WhatsApp</AccordionTrigger>
          <AccordionContent>
           <ol>
            <li>Open the chat with the contact you want to block.</li>
            <li>Tap the contact's name at the top of the screen.</li>
            <li>Scroll down and tap "Block".</li>
            <li>Confirm by tapping "Block" again.</li>
           </ol>
           <p>
            You can also go to Settings &gt; Account &gt; Privacy &gt; Blocked
            contacts to manage your blocked list.
           </p>
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-4">
          <AccordionTrigger>
           What happens when you block a number?
          </AccordionTrigger>
          <AccordionContent>
           <p>When you block a number:</p>
           <ul>
            <li>You won't receive calls or messages from that number.</li>
            <li>
             The blocked caller usually won't be notified that they've been
             blocked.
            </li>
            <li>
             Blocked calls typically go straight to voicemail (if available).
            </li>
            <li>Messages from blocked numbers won't be delivered.</li>
           </ul>
          </AccordionContent>
         </AccordionItem>

         <AccordionItem value="item-5">
          <AccordionTrigger>Can I unblock a number later?</AccordionTrigger>
          <AccordionContent>
           <p>
            Yes, you can unblock numbers at any time. The process is usually
            similar to blocking:
           </p>
           <ul>
            <li>
             On iPhone: Go to Settings &gt; Phone &gt; Blocked Contacts, then
             swipe left on the number and tap "Unblock".
            </li>
            <li>
             On Android: Go to your Phone app settings, find the block list, and
             remove the number.
            </li>
            <li>
             On WhatsApp: Go to Settings &gt; Account &gt; Privacy &gt; Blocked
             contacts, then tap the number and select "Unblock".
            </li>
           </ul>
          </AccordionContent>
         </AccordionItem>
         <AccordionItem value="item-12">
          <AccordionTrigger>
           How else do you protect prospects from spam?
          </AccordionTrigger>
          <AccordionContent>
           <p>
            Your safety and satisfaction are our top priorities. Here's how we
            work to protect you:
           </p>
           <ul>
            <li>
             We carefully vet all advisors before they join our platform.
            </li>
            <li>
             We monitor communication patterns to detect potential spam
             proactively.
            </li>
            <li>
             We provide you with easy-to-use blocking and reporting tools.
            </li>
            <li>
             We continuously update our anti-spam technology based on user
             feedback.
            </li>
            <li>
             We offer support if you ever feel uncomfortable or need assistance.
            </li>
           </ul>
          </AccordionContent>
         </AccordionItem>
        </Accordion>
       </ScrollArea>
      </div>
     </SheetDescription>
    </SheetHeader>
   </SheetContent>
  </Sheet>
 );
}
